<template>
    <div class="al-content">
        <div class="About">
            <h1>Setting</h1>
            <div class="panel panel-blur profile-page animated zoomIn">
                <div class="panel-body">
                    <div class="panel-content ">                        
                        <h3 class="with-line pt-0">Send Email Notifications</h3>
                        <div class="notification row clearfix">
                            <div class="col-sm-6">
                                <div class="form-group row clearfix">
                                    <label class="col-xs-8">When I receive a message</label>
                                    <div class="col-xs-4">
                                        <div class="switch-container primary " color="primary">
                                            <div class="bootstrap-switch-off bootstrap-switch-small bootstrap-switch bootstrap-switch-wrapper bootstrap-switch-animate" style="width: 86px;">
                                                <div class="bootstrap-switch-container" style="width: 126px; margin-left: -42px;">
                                                    <span class="bootstrap-switch-handle-on bootstrap-switch-primary" style="width: 42px;">ON</span>
                                                    <span class="bootstrap-switch-label" style="width: 42px;">&nbsp;</span>
                                                    <span class="bootstrap-switch-handle-off bootstrap-switch-default" style="width: 42px;">OFF</span>
                                                    <input type="checkbox" class="ng-pristine ng-untouched ng-valid ng-not-empty">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row clearfix">
                                    <label class="col-xs-8">When Someone sends me an invitation</label>
                                    <div class="col-xs-4">
                                        <div class="switch-container primary " color="primary">
                                            <div class="bootstrap-switch-off bootstrap-switch-small bootstrap-switch bootstrap-switch-wrapper bootstrap-switch-animate" style="width: 86px;">
                                                <div class="bootstrap-switch-container" style="width: 126px; margin-left: -42px;">
                                                    <span class="bootstrap-switch-handle-on bootstrap-switch-primary" style="width: 42px;">ON</span>
                                                    <span class="bootstrap-switch-label" style="width: 42px;">&nbsp;</span>
                                                    <span class="bootstrap-switch-handle-off bootstrap-switch-default" style="width: 42px;">OFF</span>
                                                    <input type="checkbox" class="ng-pristine ng-untouched ng-valid ng-not-empty">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row clearfix">
                                    <label class="col-xs-8">When profile information changes</label>
                                    <div class="col-xs-4">
                                        <div class="switch-container primary " color="primary">
                                            <div class="bootstrap-switch-off bootstrap-switch-small bootstrap-switch bootstrap-switch-wrapper bootstrap-switch-animate" style="width: 86px;">
                                                <div class="bootstrap-switch-container" style="width: 126px; margin-left: -42px;">
                                                    <span class="bootstrap-switch-handle-on bootstrap-switch-primary" style="width: 42px;">ON</span>
                                                    <span class="bootstrap-switch-label" style="width: 42px;">&nbsp;</span>
                                                    <span class="bootstrap-switch-handle-off bootstrap-switch-default" style="width: 42px;">OFF</span>
                                                    <input type="checkbox" class="ng-pristine ng-untouched ng-valid ng-empty">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="form-group row clearfix">
                                    <label class="col-xs-8">When anyone logs into your account from a new device or browser</label>
                                    <div class="col-xs-4">
                                        <div class="switch-container primary " color="primary">
                                            <div class="bootstrap-switch-off bootstrap-switch-small bootstrap-switch bootstrap-switch-wrapper bootstrap-switch-animate" style="width: 86px;">
                                                <div class="bootstrap-switch-container" style="width: 126px; margin-left: -42px;">
                                                    <span class="bootstrap-switch-handle-on bootstrap-switch-primary" style="width: 42px;">ON</span>
                                                    <span class="bootstrap-switch-label" style="width: 42px;">&nbsp;</span>
                                                    <span class="bootstrap-switch-handle-off bootstrap-switch-default" style="width: 42px;">OFF</span>
                                                    <input type="checkbox" class="ng-pristine ng-untouched ng-valid ng-not-empty">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row clearfix">
                                    <label class="col-xs-8">Weekly Reports</label>
                                    <div class="col-xs-4">
                                        <div class="switch-container primary " color="primary">
                                            <div class="bootstrap-switch-off bootstrap-switch-small bootstrap-switch bootstrap-switch-wrapper bootstrap-switch-animate" style="width: 86px;">
                                                <div class="bootstrap-switch-container" style="width: 126px; margin-left: -42px;">
                                                    <span class="bootstrap-switch-handle-on bootstrap-switch-primary" style="width: 42px;">ON</span>
                                                    <span class="bootstrap-switch-label" style="width: 42px;">&nbsp;</span>
                                                    <span class="bootstrap-switch-handle-off bootstrap-switch-default" style="width: 42px;">OFF</span>
                                                    <input type="checkbox" class="ng-pristine ng-untouched ng-valid ng-not-empty">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row clearfix">
                                    <label class="col-xs-8">Daily Reports</label>
                                    <div class="col-xs-4">
                                        <div class="switch-container primary " color="primary">
                                            <div class="bootstrap-switch-off bootstrap-switch-small bootstrap-switch bootstrap-switch-wrapper bootstrap-switch-animate" style="width: 86px;">
                                                <div class="bootstrap-switch-container" style="width: 126px; margin-left: -42px;">
                                                    <span class="bootstrap-switch-handle-on bootstrap-switch-primary" style="width: 42px;">ON</span>
                                                    <span class="bootstrap-switch-label" style="width: 42px;">&nbsp;</span>
                                                    <span class="bootstrap-switch-handle-off bootstrap-switch-default" style="width: 42px;">OFF</span>
                                                    <input type="checkbox" class="ng-pristine ng-untouched ng-valid ng-empty">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button type="button" class="btn btn-primary btn-with-icon save-profile"
                                @click="this.$store.dispatch('view/showToast', { title: '', content: `Your setting is saved.<br /><b class=red-text>Note</b>: it\'s just a demo page.`, type: 'success' });"
                                >
                            <i class="ion-android-checkmark-circle"></i>Update Setting
                        </button>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>


export default {
  name: `UserSetting`,

};
</script>
